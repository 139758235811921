import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import PackageContext from "./context";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MyModalComponent() {
  const saveValetTrip = (context) => {
    if (context.tripDetails.driverName === "") {
      toast.error("Please enter Driver Name");
    } else if (context.tripDetails.driverMobileNo === "") {
      toast.error("Please enter Driver Mobile No");
    } else if (
      context.tripDetails.tripId > 0 &&
      context.tripDetails.tripStatus === ""
    ) {
      toast.error("Please select Trip Status");
    } else {
      if (context.tripDetails.tripId === 0) {
        Axios.post("https://uatapi.park4cars.com/postBooking/manageValetTrip", {
          bookingId: context.tripDetails.bookingId,
          tripType: context.tripDetails.tripType,
          driverName: context.tripDetails.driverName,
          driverMobileNo: context.tripDetails.driverMobileNo,
        })
          .then(function (response) {
            if (response.data.statusCode === 200) {
              context.updateValetTripInBooking(response.data.data.valetTrip);
              context.updateIsModalOpen(false);
              context.clearTripDetails();
              toast.success("Trip added successfully");
            }
          })
          .catch(function (error) {
            toast.error(error);
          });
      } else {
        Axios.put("https://uatapi.park4cars.com/postBooking/updateValetTrip", {
          bookingId: context.tripDetails.bookingId,
          tripType: context.tripDetails.tripType,
          driverName: context.tripDetails.driverName,
          driverMobileNo: context.tripDetails.driverMobileNo,
          tripStatus: context.tripDetails.tripStatus,
        })
          .then(function (response) {
            context.updateValetTripInBooking(response.data.data[0]);
            context.updateIsModalOpen(false);
            context.clearTripDetails();
            toast.success("Trip updated successfully");
          })
          .catch(function (error) {
            toast.error(error);
          });
      }
    }
  };

  return (
    <PackageContext.Consumer>
      {(context) => (
        <Modal
          show={context.isOpen}
          onHide={context.handleCloseModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <ToastContainer position="bottom-right" />
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {context.tripDetails.tripId !== null &&
              context.tripDetails.tripId !== 0
                ? "Update Valet Trip"
                : "Add Valet Trip"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formDriverName">
                <Form.Label>Driver Name *</Form.Label>
                <Form.Control
                  type="text"
                  value={context.tripDetails.driverName}
                  placeholder="Enter Driver Name"
                  onChange={(event) =>
                    context.updateDriverNameInTripDetails(event.target.value)
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Driver Name
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formDriverMobileNo">
                <Form.Label>Driver Mobile No *</Form.Label>
                <Form.Control
                  type="text"
                  value={context.tripDetails.driverMobileNo}
                  placeholder="Enter Driver Mobile No"
                  onChange={(event) =>
                    context.updateDriverMobileNoInTripDetails(
                      event.target.value
                    )
                  }
                />
              </Form.Group>

              {context.tripDetails.tripId !== null &&
                context.tripDetails.tripId !== 0 && (
                  <Form.Group className="mb-3" controlId="formTripStatus">
                    <Form.Label>Trip Status *</Form.Label>

                    <Form.Control
                      as="select"
                      value={context.tripDetails.tripStatus}
                      onChange={(e) => {
                        context.updateTripStatusInTripDetails(e.target.value);
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="NOTSTARTED">Not Started</option>
                      <option value="ONGOING">Ongoing</option>
                      <option value="COMPLETE">Complete</option>
                    </Form.Control>
                  </Form.Group>
                )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={context.handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={() => saveValetTrip(context)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </PackageContext.Consumer>
  );
}

export default MyModalComponent;
