import React from "react";
import Context from "./context";

const BookingDetailsAccordian = ({ bookingRecord, index }) => {
  var pickupTrip =
    bookingRecord.valetTrip != null
      ? bookingRecord.valetTrip.filter((v) => v.TripType === "PICKUP")
      : [];

  var dropTrip =
    bookingRecord.valetTrip != null
      ? bookingRecord.valetTrip.filter((v) => v.TripType === "DROP")
      : [];

  return (
    <Context.Consumer>
      {(context) => (
        <div id="accordion" className="accordion">
          <div className="accordion-item">
            <a
              href="#"
              className="accordion-head collapsed"
              data-toggle="collapse"
              data-target={"#accordion-item-" + (index + 1)}
            >
              <h6
                className="title col-md-3"
                id={"bookingid-" + (index + 1)}
                style={{ display: "inline" }}
              >
                {bookingRecord.booking.BookingMode +
                  " Booking ID - " +
                  bookingRecord.booking.id}
              </h6>
              <h6
                className="title col-md-3"
                id={"custmobno-" + (index + 1)}
                style={{ display: "inline" }}
              >
                {"Customer Mob No : " + bookingRecord.user.MobileNo}
              </h6>
              <h6
                className="title col-md-3"
                id={"vehicleno-" + (index + 1)}
                style={{ display: "inline" }}
              >
                {"Vehicle No : " + bookingRecord.vehicle.RegistrationNo}
              </h6>
              <h6
                className="title col-md-3"
                id={"bookst-" + (index + 1)}
                style={{ display: "inline" }}
              >
                {"Booking Status : " + bookingRecord.booking.BookingStatus}
              </h6>
              <span className="accordion-icon"></span>
            </a>
            <div
              className="accordion-body collapse"
              id={"accordion-item-" + (index + 1)}
              data-parent="#accordion"
            >
              <div className="accordion-inner">
                <h6 className="title">Booking Details</h6>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">From Time</th>
                        <th scope="col">To Time</th>
                        <th scope="col">Pickup Address</th>
                        <th scope="col">Parking Slot Address</th>
                        <th scope="col">Drop Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td id={"bookingtype-" + (index + 1)}>
                          {bookingRecord.booking.BookingType}
                        </td>
                        <td id={"fromtime-" + (index + 1)}>
                          {bookingRecord.booking.FromTime}
                        </td>
                        <td id={"totime-" + (index + 1)}>
                          {bookingRecord.booking.ToTime}
                        </td>
                        <td id={"pickupaddress-" + (index + 1)}>
                          {bookingRecord.booking.PickupAddress +
                            " \n(Lat: " +
                            bookingRecord.booking.PickupLatitude +
                            ", Long: " +
                            bookingRecord.booking.PickupLongitude +
                            ")"}
                        </td>
                        <td id={"parkingslotaddress-" + (index + 1)}>
                          {bookingRecord.parkingSlot.SlotName +
                            " " +
                            bookingRecord.parkingSlot.AddressLine1 +
                            " " +
                            bookingRecord.parkingSlot.AddressLine2 +
                            " " +
                            bookingRecord.parkingSlot.Landmark +
                            " " +
                            bookingRecord.parkingSlot.City +
                            " Pincode : " +
                            bookingRecord.parkingSlot.Pincode +
                            " \n(Lat: " +
                            bookingRecord.parkingSlot.Latitude +
                            ", Long: " +
                            bookingRecord.parkingSlot.Longitude +
                            ")"}
                        </td>
                        <td id={"dropaddress-" + (index + 1)}>
                          {bookingRecord.booking.DropAddress +
                            " \n(Lat: " +
                            bookingRecord.booking.DropLatitude +
                            ", Long: " +
                            bookingRecord.booking.DropLongitude +
                            ")"}
                        </td>
                      </tr>

                      {bookingRecord.childBooking.map(
                        (childBookingRecord, childIndex) => {
                          <tr>
                            <td
                              id={
                                "bookingtype-" + (index + 1) + "-" + childIndex
                              }
                            >
                              {childBookingRecord.booking.BookingType}
                            </td>
                            <td
                              id={"fromtime-" + (index + 1) + "-" + childIndex}
                            >
                              {childBookingRecord.booking.FromTime}
                            </td>
                            <td id={"totime-" + (index + 1) + "-" + childIndex}>
                              {childBookingRecord.booking.ToTime}
                            </td>
                            <td
                              id={
                                "pickupaddress-" +
                                (index + 1) +
                                "-" +
                                childIndex
                              }
                            >
                              {""}
                            </td>
                            <td
                              id={
                                "parkingslotaddress-" +
                                (index + 1) +
                                "-" +
                                childIndex
                              }
                            >
                              {""}
                            </td>
                            <td
                              id={
                                "dropaddress-" + (index + 1) + "-" + childIndex
                              }
                            >
                              {""}
                            </td>
                          </tr>;
                        }
                      )}
                    </tbody>
                  </table>
                </div>

                {bookingRecord.booking.BookingMode === "VALET" && (
                  <>
                    <h6 className="title">Trip Details</h6>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Driver</th>
                            <th scope="col">Number</th>
                            <th scope="col">Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td id={"typepickup-" + (index + 1)}>PICKUP</td>
                            <td id={"pickupdriver-" + (index + 1)}>
                              {pickupTrip.length > 0
                                ? pickupTrip[0].DriverName
                                : ""}
                            </td>
                            <td id={"pickupdrivernum-" + (index + 1)}>
                              {pickupTrip.length > 0
                                ? pickupTrip[0].DriverMobileNo
                                : ""}
                            </td>
                            <td id={"pickuptripstatus-" + (index + 1)}>
                              {pickupTrip.length > 0
                                ? pickupTrip[0].TripStatus
                                : ""}
                            </td>
                            <td>
                              <a
                                href="#"
                                className="btn btn-outline-secondary"
                                data-toggle="modal"
                                data-target="#modalForm"
                                onClick={() =>
                                  context.openFromParent({
                                    bookingId: bookingRecord.booking.id,
                                    tripType: "PICKUP",
                                    tripId:
                                      pickupTrip.length > 0
                                        ? pickupTrip[0].id
                                        : 0,
                                    tripStatus:
                                      pickupTrip.length > 0
                                        ? pickupTrip[0].TripStatus
                                        : "",
                                    driverName:
                                      pickupTrip.length > 0
                                        ? pickupTrip[0].DriverName
                                        : "",
                                    driverMobileNo:
                                      pickupTrip.length > 0
                                        ? pickupTrip[0].DriverMobileNo
                                        : "",
                                  })
                                }
                              >
                                {pickupTrip.length > 0 && pickupTrip[0].id > 0
                                  ? "Update"
                                  : "Add"}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td id={"typedrop-" + (index + 1)}>DROP</td>
                            <td id={"dropdriver-" + (index + 1)}>
                              {dropTrip.length > 0
                                ? dropTrip[0].DriverName
                                : ""}
                            </td>
                            <td id={"dropdrivernum-" + (index + 1)}>
                              {dropTrip.length > 0
                                ? dropTrip[0].DriverMobileNo
                                : ""}
                            </td>
                            <td id={"droptripstatus-" + (index + 1)}>
                              {dropTrip.length > 0
                                ? dropTrip[0].TripStatus
                                : ""}
                            </td>
                            <td>
                              <a
                                href="#"
                                className="btn btn-outline-secondary"
                                data-toggle="modal"
                                data-target="#modalForm"
                                onClick={() =>
                                  context.openFromParent({
                                    bookingId: bookingRecord.booking.id,
                                    tripType: "DROP",
                                    tripId:
                                      dropTrip.length > 0 ? dropTrip[0].id : 0,
                                    tripStatus:
                                      dropTrip.length > 0
                                        ? dropTrip[0].TripStatus
                                        : "",
                                    driverName:
                                      dropTrip.length > 0
                                        ? dropTrip[0].DriverName
                                        : "",
                                    driverMobileNo:
                                      dropTrip.length > 0
                                        ? dropTrip[0].DriverMobileNo
                                        : "",
                                  })
                                }
                              >
                                {dropTrip.length > 0 && dropTrip[0].id > 0
                                  ? "Update"
                                  : "Add"}
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Context.Consumer>
  );
};

export default BookingDetailsAccordian;
