import React from "react";

const Header = () => {
  return (
    <div className="nk-block-head nk-block-head-sm">
      <div className="nk-block-between">
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">Valet Dashboard</h3>
        </div>

        <div className="nk-block-head-content">
          <div className="toggle-wrap nk-block-tools-toggle">
            <a
              href="#"
              className="btn btn-icon btn-trigger toggle-expand mr-n1"
              data-target="pageMenu"
            >
              <em className="icon ni ni-more-v"></em>
            </a>
            <div className="toggle-expand-content" data-content="pageMenu">
              <ul className="nk-block-tools g-3">
                <li>
                  <div className="drodown">
                    <a
                      href="#"
                      className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                      data-toggle="dropdown"
                    >
                      <em className="d-none d-sm-inline icon ni ni-call"></em>
                      <span>
                        <span className="d-none d-md-inline">Contact</span> Info
                      </span>
                      <em className="dd-indc icon ni ni-chevron-right"></em>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <a href="#">
                            <span>P4C : 9819293747</span>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>P4C : 9819236651</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
