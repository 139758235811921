import React from "react";

const Card = ({ title, id, text }) => {
  return (
    <div className="card card-bordered card-full">
      <div className="card-inner">
        <div className="card-title-group align-start mb-0">
          <div className="card-title">
            <h4 className="subtitle">{title}</h4>
          </div>
          <h4 className="amount" id={id}>
            {" "}
            {text}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Card;
