import React, { useContext, useEffect } from "react";
import BookingDetailsAccordian from "./BookingDetailsAccordian";
import Card from "./Card";
import Axios from "axios";
import MyModalComponent from "./AddUpdateValetTripModal";
import PackageContext from "./context";

const Content = () => {
  let packageContext = useContext(PackageContext);

  const loadBookings = async (context) => {
    console.log("Load Booking");

    let response = await Axios.get(
      "https://uatapi.park4cars.com/booking/upcomingValetBooking"
    );

    if (response.status === 200) {
      const myJson = response.data.data;
      context.setBookingsInState(myJson);
    }
  };

  useEffect(() => {
    const dataTimer = setInterval(() => {
      loadBookings(packageContext);
    }, 1000 * 60 * 10);
    return () => clearInterval(dataTimer);
  }, []);

  useEffect(() => {
    loadBookings(packageContext);
  }, []);

  return (
    <PackageContext.Consumer>
      {(context) => (
        <div className="nk-block">
          <div className="row g-gs">
            <div className="col-md-4">
              <Card
                title="Pickup Not Assigned"
                id="pickupnotassigned"
                text={context.pickUpNotAssignedCount}
              />
            </div>
            <div className="col-md-4">
              <Card
                title="Drop Not Assigned"
                id="dropnotassigned"
                text={context.dropNotAssignedCount}
              />
            </div>
            <div className="col-md-4">
              <Card
                title="Complete"
                id="complete"
                text={context.completedCount}
              />
            </div>
            <div className="col-xl-12 col-xxl-8">
              {context.bookings.map((bookingRecord, index) => (
                <BookingDetailsAccordian
                  key={bookingRecord.booking.id}
                  bookingRecord={bookingRecord}
                  index={index}
                />
              ))}
            </div>
          </div>

          <MyModalComponent />
        </div>
      )}
    </PackageContext.Consumer>
  );
};

export default Content;
