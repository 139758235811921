import React, { useState } from "react";
import Context from "./context";

const Provider = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTripDetails, setModalTripDetails] = useState({});
  const [bookings, setBookings] = useState([]);
  const [pickUpNotAssignedCount, setPickUpNotAssignedCount] = useState(0);
  const [dropNotAssignedCount, setDropUpNotAssignedCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);

  return (
    <Context.Provider
      value={{
        isOpen: modalIsOpen,
        tripDetails: modalTripDetails,
        bookings: bookings,
        pickUpNotAssignedCount: pickUpNotAssignedCount,
        dropNotAssignedCount: dropNotAssignedCount,
        completedCount: completedCount,
        setBookingsInState: (bookingsArray) => {
          setBookings(bookingsArray);

          var pickupnotassigned = 0;
          var completed = 0;
          var dropnotassigned = 0;

          for (let index = 0; index < bookingsArray.length; index++) {
            var i = index;
            if (bookingsArray[i].booking.BookingMode === "VALET") {
              if (
                bookingsArray[i].valetTrip != null &&
                bookingsArray[i].valetTrip.length > 0
              ) {
                if (
                  bookingsArray[i].valetTrip.length === 2 &&
                  bookingsArray[i].valetTrip[0].TripStatus === "COMPLETE" &&
                  bookingsArray[i].valetTrip[1].TripStatus === "COMPLETE"
                ) {
                  completed += 1;
                } else if (
                  bookingsArray[i].valetTrip.length === 1 &&
                  bookingsArray[i].valetTrip[0].TripStatus === "COMPLETE"
                )
                  dropnotassigned += 1;
              } else {
                pickupnotassigned += 1;
              }
            }
          }

          setPickUpNotAssignedCount(pickupnotassigned);
          setDropUpNotAssignedCount(dropnotassigned);
          setCompletedCount(completed);
        },
        updateValetTripInBooking: (valetTrip) => {
          var selectedBooking = bookings.find(
            (b) => b.booking.id === valetTrip.BookingId
          );

          if (selectedBooking) {
            if (selectedBooking.valetTrip == null) {
              selectedBooking.valetTrip = [valetTrip];
            } else {
              var selectedValetTripIndex = selectedBooking.valetTrip.findIndex(
                (v) => v.TripType === valetTrip.TripType
              );

              if (selectedValetTripIndex >= 0) {
                selectedBooking.valetTrip[selectedValetTripIndex] = valetTrip;
              } else {
                selectedBooking.valetTrip.push(valetTrip);
              }
            }

            setBookings(bookings);

            var pickupnotassigned = 0;
            var completed = 0;
            var dropnotassigned = 0;

            for (let index = 0; index < bookings.length; index++) {
              var i = index;
              if (bookings[i].booking.BookingMode === "VALET") {
                if (
                  bookings[i].valetTrip != null &&
                  bookings[i].valetTrip.length > 0
                ) {
                  if (
                    bookings[i].valetTrip.length === 2 &&
                    bookings[i].valetTrip[0].TripStatus === "COMPLETE" &&
                    bookings[i].valetTrip[1].TripStatus === "COMPLETE"
                  ) {
                    completed += 1;
                  } else if (
                    bookings[i].valetTrip.length === 1 &&
                    bookings[i].valetTrip[0].TripStatus === "COMPLETE"
                  )
                    dropnotassigned += 1;
                } else {
                  pickupnotassigned += 1;
                }
              }
            }

            setPickUpNotAssignedCount(pickupnotassigned);
            setDropUpNotAssignedCount(dropnotassigned);
            setCompletedCount(completed);
          }
        },
        updateIsModalOpen: (open) => {
          setModalIsOpen(open);
        },
        clearTripDetails: () => {
          setModalTripDetails({});
        },
        updateDriverNameInTripDetails: (inputDriverName) => {
          setModalTripDetails({
            ...modalTripDetails,
            driverName: inputDriverName,
          });
        },
        updateDriverMobileNoInTripDetails: (inputDriverMobileNo) => {
          setModalTripDetails({
            ...modalTripDetails,
            driverMobileNo: inputDriverMobileNo,
          });
        },
        updateTripStatusInTripDetails: (inputTripStatus) => {
          setModalTripDetails({
            ...modalTripDetails,
            tripStatus: inputTripStatus,
          });
        },
        openFromParent: (tripDetails) => {
          setModalIsOpen(true);
          setModalTripDetails(tripDetails);
        },
        handleAfterOpen: (event, data) => {
          console.log(event, data);
        },
        handleCloseModal: (event, data) => {
          setModalIsOpen(false);
          setModalTripDetails({});
        },
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default Provider;
