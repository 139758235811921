import React from "react";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

import Provider from "./provider";

const App = () => {
  return (
    <Provider>
      <div className="nk-main ">
        <div className="nk-wrap">
          <div className="nk-content pt-1">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <Header />
                  <Content />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Provider>
  );
};

export default App;
